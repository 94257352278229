import React from "react";
import { useLocation } from "react-router-dom";

import { useSiteData } from "../SiteDataContext";

function Footer() {
  const { siteData } = useSiteData();

  return (
    <footer className=" w-full sticky bg-dark py-20">
      <div className="container flex flex-wrap flex-col md:flex-row gap-7 md:gap-0 justify-between mx-auto pb-16">
        <div className="w-full md:w-1/2 text-white pb-30 md:pb-0">
          <div className="text-c2rem font-extrabold pb-3">
            Возникли вопросы или предложения?
          </div>
          <div className="text-2xl">
            Не стесняйтесь, напишите нам в Телеграмм
          </div>
        </div>
        <div className="w-full lg:w-1/4 text-white">
          <a className="button block cursor-pointer font-extrabold border-[3px] rounded-lg border-r-12 text-center p-[20px] text-[24px]">
            Бот обратной связи
          </a>
        </div>
      </div>

      <div className="container flex flex-wrap flex-col md:flex-row gap-7 md:gap-0  mx-auto pt-14">
        <div className="w-full md:w-1/2 text-light pb-30 md:pb-0 sm:text-s">
          <div
            dangerouslySetInnerHTML={{
              __html: siteData?.copyright?.content,
            }}
          />
        </div>
        <div className="w-full md:w-1/2 text-light">
          <ul className="flex flex-wrap flex-col md:flex-row md:items-center lg:items-end lg:items-start justify-start md:justify-end w-full gap-3 sm:text-s">
            <li>
              <a href="">Privacy Policy</a>
            </li>
            <li>
              <a href="">Terms & Conditions</a>
            </li>
            <li>
              <a href="">Cookie Policy</a>
            </li>
            <li>
              <a href="">Contact</a>
            </li>
          </ul>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
