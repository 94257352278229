import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import axios from "axios";
import { useEffect, useState } from "react";

import { REACT_APP_API_URL } from "./config";

import Main from "./pages/Main";
import RatingPage from "./pages/RatingPage";

import { SiteDataProvider } from "./SiteDataContext";

function App() {
  const [siteData, setSiteData] = useState({});

  useEffect(() => {
    axios
      .get(`${REACT_APP_API_URL}/api/site-texts/`)
      .then((response) => {
        setSiteData(response.data);
      })
      .catch((error) => {
        console.error("There was an error fetching the data!", error);
      });
  }, []);

  return (
    <SiteDataProvider siteData={siteData} setSiteData={setSiteData}>
      <Router>
        <Routes>
          <Route path="/" element={<Main />} />
          <Route path="/rating" element={<RatingPage />} />
        </Routes>
      </Router>
    </SiteDataProvider>
  );
}

export default App;
