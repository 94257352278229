import React from "react";
import { useLocation } from "react-router-dom";

import { useSiteData } from "../SiteDataContext";

import logo from "../assets/img/logo.svg";
import mobileMenuIcon from "../assets/img/icons/mobileMenuIcon.svg";

function Header() {
  const location = useLocation();
  const { pathname } = location;
  const { siteData, setSiteData } = useSiteData();

  return (
    <header className="w-full bg-dark">
      <div className="header-top container max-w-7xl mx-auto md:w-full text-white hidden py-7 xs:hidden lg:flex">
        <div className="w-1/2 flex items-center flex items-center justify-start font-black sm:text-2xl lg:text-c2rem">
          <a href="/">VPN гид</a>
        </div>
        <div className="w-1/2 flex ">
          <ul className="flex items-center justify-end w-full gap-10 text-lg font-black">
            <li>
              <a href="/timeline.html">Рейтинг VPN</a>
            </li>
            <li>
              <a href="/about.html">Как выбрать VPN</a>
            </li>
            <li>
              <a href="/contacts.html">Контакты</a>
            </li>
          </ul>
        </div>
      </div>

      <div className="header-top container mx-auto xl:w-full md:w-full text-white flex py-4 md:flex lg:hidden">
        <div className="w-1/3 flex items-center">
          <div className="font-black xs:text-xl lg:text-c2rem">VPN гид</div>
        </div>
        <div className="w-1/3 flex items-center flex items-center justify-center">
          <img src="./assets/images/Logo 4.svg" alt="" />
        </div>
        <div className="w-1/3 flex items-center justify-end">
          <svg
            width="42"
            height="18"
            viewBox="0 0 42 18"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <rect
              x="42"
              y="18"
              width="30"
              height="2"
              rx="1"
              transform="rotate(-180 42 18)"
              fill="#D9D9D9"
            />
            <rect
              x="36"
              y="10"
              width="30"
              height="2"
              rx="1"
              transform="rotate(-180 36 10)"
              fill="#D9D9D9"
            />
            <rect
              x="30"
              y="2"
              width="30"
              height="2"
              rx="1"
              transform="rotate(-180 30 2)"
              fill="#D9D9D9"
            />
          </svg>

          <ul className="flex items-center justify-end w-full gap-10 text-lg hidden">
            <li>
              <a href="/timeline.html">Таймлайн</a>
            </li>
            <li>
              <a href="/about.html">О проекте</a>
            </li>
            <li>
              <a href="/contacts.html">Контакт</a>
            </li>
          </ul>
        </div>
      </div>
    </header>
  );
}

export default Header;
