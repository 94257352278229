import React from "react";

function HtmlDisplay({ html }) {
  return (
    <span
      dangerouslySetInnerHTML={{
        __html: html,
      }}
    />
  );
}

export default HtmlDisplay;
