import React, { useEffect, useState } from "react";

import searchIcon from "../assets/img/icons/search.svg";

function TabSwitch({ ratingList, setActiveId = () => {} }) {
  const [activeId, _setActiveId] = useState();

  const __setActive = (itemId) => {
    if (activeId) {
      setActiveId(itemId);
    }
    _setActiveId(itemId);
  };

  useEffect(() => {
    if (!ratingList.length) return;
    __setActive(ratingList[0]?.id);
  }, [ratingList]);

  return (
    <div className="flex justify-between items-center lg:max-w-screen-sm md:gap-12 xs:gap-2 font-bold text-dark tab-switcher">
      {ratingList.map((rating, index) => (
        <a
          key={index}
          className={`py-3 whitespace-nowrap cursor-pointer ${
            rating.id == activeId ? "active" : ""
          }`}
          onClick={() => {
            __setActive(rating.id);
          }}
        >
          {rating.name}
        </a>
      ))}

      <div className="search-btn rounded-lg bg-light px-3 py-2 flex flex-shrink-0">
        <img src={searchIcon} alt="" />
      </div>
    </div>
  );
}

export default TabSwitch;
