import Header from "../components/Header";
import { Link } from "react-router-dom";
import { useParams } from "react-router-dom";
import { useSiteData } from "../SiteDataContext";
import Footer from "../components/Footer";

import axios from "axios";
import { REACT_APP_API_URL } from "../config";
import { useEffect, useState } from "react";
import HtmlDisplay from "../components/HtmlDisplay";
import TabSwitch from "../components/TabSwitch";

function RatingPage() {
  const { reportId } = useParams();
  const { siteData } = useSiteData();

  const [ratingList, setRatingList] = useState([]);

  const updateHash = (newHash) => {
    window.history.pushState(null, "", `#${newHash}`);
  };

  const handleScroll = (targetId) => {
    const section = document.getElementById(targetId);
    if (section) {
      section.scrollIntoView({ behavior: "smooth", block: "start" });
      setTimeout(() => {
        updateHash(targetId);
      }, 50);
    }
  };

  const handleSetActiveTabId = (id) => {
    handleScroll(`rating-section-${id}`);
  };

  useEffect(() => {
    if (!ratingList.length) return;

    const hash = window.location.hash;
    if (hash) {
      const param = hash.substring(1);
      handleScroll(param);
    }
  }, [ratingList]);

  useEffect(() => {
    axios
      .get(`${REACT_APP_API_URL}/api/rating_types/`)
      .then((response) => {
        const { data } = response;
        data.sort((a, b) => a.order - b.order);
        setRatingList(data);
      })
      .catch((error) => {
        console.error("There was an error fetching the data!", error);
      });
  }, []);

  return (
    <div className="bg-white dark:bg-dark mainpage">
      <Header />

      <section className="main-screen lg:pt-5 lg:pb-20 xs:pt-16 xs:pb-16  w-full bg-white overflow-hidden relative">
        <div className="container mx-auto md:pt-5 md:mb-20 tabs flex justify-between items-center gap-5 md:flex-row xs:flex-col border-b-2 border-light md:px-0 max-w-7xl">
          <div className="md:w-1/2 xs:w-full">
            <TabSwitch
              ratingList={ratingList}
              setActiveId={handleSetActiveTabId}
            />
          </div>
          <div className="md:w-1/2 xs:w-full flex md:justify-end xs:justify-start md:pb-0 xs:pb-3">
            <a href="" className="font-bold text-base">
              Не нашли свой продукт?
            </a>
          </div>
        </div>

        {ratingList.map((rating, index) => (
          <div
            key={index}
            className="container mx-auto max-w-7xl relative xs:pt-10 md:pt-0 md:pb-20 xs:pb-10"
            id={`rating-section-${rating.id}`}
          >
            <div className="md:text-5xl xs:text-3xl font-extrabold text-dark pb-5">
              {rating.name}
            </div>
            <p className="text-dark md:text-2xl xs:text-xl  pb-9">
              <HtmlDisplay html={rating.description} />
            </p>

            {rating.vpns.map((vpn, _index) => (
              <div key={_index} className="cards flex flex-col gap-3 mb-3">
                <div className="card flex lg:gap-0 xs:gap-8 justify-between items-stretch bg-lightBlue xs:flex-col md:flex-row p-5 rounded-lg flex-wrap">
                  <div className="md:w-3/4 xs:w-full flex gap-6 md:items-center xs:items-start xs:flex-col md:flex-row">
                    <div
                      className={`vpn-logo flex-shrink-0 ${
                        !vpn.logo ? "bg-gray" : ""
                      }`}
                      style={{
                        backgroundImage: `url(${REACT_APP_API_URL}${vpn.logo})`,
                      }}
                    ></div>

                    <div className="md:pr-4 xs:pr-0">
                      <div className="cttl md:text-c2rem xs:text-2xl font-bold pb-2 text-dark">
                        {vpn.name}
                      </div>
                      <div className="cttl md:text-xl xs:text-base">
                        {vpn.reputation_text}
                      </div>
                    </div>
                  </div>
                  <div className="md:w-1/4 xs:w-full border-l-2 flex items-center md:justify-center xs:justify-start md:flex-col xs:flex-row md:gap-5 xs:gap-4 min-w-48">
                    <div className="rates flex gap-3 items-center justify-center md:pb-3">
                      <img
                        className="rating-icon"
                        src={`${REACT_APP_API_URL}${rating.rating_icon}`}
                        alt=""
                      />
                      <span className="md:text-4xl xs:text-lg">
                        {rating.rating}/9
                      </span>
                    </div>
                    <div className="button bg-dark text-white px-8 text-center py-4 rounded-lg">
                      Перейти к сервису
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        ))}
      </section>

      <Footer />
    </div>
  );
}

export default RatingPage;
